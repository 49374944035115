import React from "react";
import Layout_app from "../layout/Layout_app";
import { useSelector } from "react-redux";
import HeadSection from "../layout/HeadSection";
import { Box, createTheme } from "@mui/material";
const Portofolio_flutter = () => {
  const { tech } = useSelector((state) => state.data);
  let data_expertise = tech;
  let tabdata = [
    {
      img: "/img-phone.png",
      background: "linear-gradient(261deg, #bcdbff, #57a1fa)",
      title: "We Are Caring",
      desc: "La première application de recrutement éthique en Asie",
    },
    {
      img: "/img-phone2.png",
      background: "linear-gradient(80deg, #f6c0a1, #ffe5be)",
      title: "MonSuiviDiet Mobile",
      desc: "L'application mobile de suivi entre dététiciens et leurs patients.",
    },
    {
      img: "/img-phone.png",
      background: "linear-gradient(74deg, #c5adff, #e7deef)",
      title: "ActivityLab - METS-UP PRO",
      desc: "METS-UP PRO, l'application qui aide les professeurs d'EPS",
    },
    {
      img: "/img-phone.png",
      background: "linear-gradient(to right, #e35355, #d17172)",
      title: "LimiteLimite",
      desc: " Le jeu de société mondialement connu en version digitale",
    },
  ];
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  // func
  return (
    <div className="portofolio_flutter">
      {tech[2]?.data?.data?.map((i) => {
        return (
          <Box
            sx={{
              [theme.breakpoints.up("sm")]: {
                padding: "0 15%",
              },
            }}
          >
            <HeadSection
              label={i?.label}
              title={i?.title}
              description={i?.description}
              textAlign="center"
            />
          </Box>
        );
      })}

      <div className="model_app_item">
        {tech[1]?.data?.data?.map((item) => {
          return (
            <Layout_app
              background={item?.background}
              title={item.title}
              desc={item?.description}
              image={item?.image_url}
              link={item?.link}
              idProject={item?.id}
              genre={
                <div className="tecnos">
                  {item?.tags?.map((l) => {
                    return (
                      <p
                        id="element"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        {l?.genre?.image_url === null ? (
                          <div></div>
                        ) : (
                          <img
                            src={l?.genre?.image_url}
                            width="16px"
                            height="16px"
                            style={{ borderRadius: "4px" }}
                          />
                        )}

                        {l?.genre?.designation}
                      </p>
                    );
                  })}
                </div>
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default Portofolio_flutter;
