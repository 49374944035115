import { Box, Button, createTheme } from "@mui/material";
import React from "react";
import { AiOutlineArrowDown } from "react-icons/ai";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

const Layout_onboarding_side_without_btn = ({ title, description, btn }) => {
  return (
    <div className="onboard_side">
      {/* <img
        className="img1"
        src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/62615dfb208faa6739c9f17e_illustration_hero_home_right.svg"
        alt="..."
      />
      <img
        className="img2"
        src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/62615dfba29b3b0fb8ed885b_illustration_hero_home_left.svg"
        alt="..."
      /> */}
      <div className="onboard_title">
        <h1>{title}</h1>
      </div>
      <div className="onboard_desc">
        <Box
          sx={{
            // sm: { paddingLeft: padding_mobile },
            // paddingLeft: { sm: padding_mobile },

            [theme.breakpoints.up("sm")]: {
              padding: "0 100px",
              // width: "80%",
            },
          }}
        >
          <p>{description}</p>
        </Box>
      </div>
    </div>
  );
};

export default Layout_onboarding_side_without_btn;
