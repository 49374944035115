import React from "react";
import Layout_app from "../layout/Layout_app";
import { useSelector } from "react-redux";

let tabdata = [
  {
    img: "/img-phone.png",
    background: "linear-gradient(261deg, #bcdbff, #57a1fa)",
    title: "We Are Caring",
    desc: "La première application de recrutement éthique en Asie",
  },
  {
    img: "/img-phone2.png",
    background: "linear-gradient(80deg, #f6c0a1, #ffe5be)",
    title: "MonSuiviDiet Mobile",
    desc: "L'application mobile de suivi entre dététiciens et leurs patients.",
  },
  {
    img: "/img-phone.png",
    background: "linear-gradient(74deg, #c5adff, #e7deef)",
    title: "ActivityLab - METS-UP PRO",
    desc: "METS-UP PRO, l'application qui aide les professeurs d'EPS",
  },
  {
    img: "/img-phone.png",
    background: "linear-gradient(to right, #e35355, #d17172)",
    title: "LimiteLimite",
    desc: " Le jeu de société mondialement connu en version digitale",
  },
  {
    img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/624c5d9f40ffde160d61681f_image_thumbnail_application_mobile_monsuividiet-p-1080.png",
    background: "linear-gradient(80deg, #f6c0a1, #ffe5be)",
    title: "MonSuiviDiet Mobile",
    desc: "L'application mobile de suivi entre dététiciens et leurs patients.",
  },
  {
    img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/63ea65fc9ba7c9ffcfea83fd_image_thumbnail_application_web_monsuividiet.webp",
    background: "linear-gradient(74deg, #c5adff, #e7deef)",
    title: "MonSuiviDiet Web",
    desc: "La plateforme Saas pour aider les professionels de santé et leur patients",
  },
];

const Portofolio_content = () => {
  const { data, isLoading, isError, porto } = useSelector(
    (state) => state.data
  );
  let data_section_two = porto[1]?.data?.data;

  return (
    <div className="portofolio_content">
      <div className="model_app_item">
        {data_section_two?.map((item) => {
          return (
            <Layout_app
              background={item?.background}
              title={item?.title}
              desc={item?.description}
              image={item?.image_url}
              link={item?.link}
              idProject={item?.id}
              genre={
                <div className="tecnos">
                  {item?.tags?.map((l) => {
                    return (
                      <p
                        id="element"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        {l?.genre?.image_url === null ? (
                          <div></div>
                        ) : (
                          <img
                            src={l?.genre?.image_url}
                            width="16px"
                            height="16px"
                            style={{ borderRadius: "4px" }}
                          />
                        )}

                        {l?.genre?.designation}
                      </p>
                    );
                  })}
                </div>
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default Portofolio_content;
