import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "../components/Navbar";
import Home from "../page/Home";
import ScrollToTop from "../layout/ScrollToTop";
import menus from "../../model/menu";
import Footer from "../components/Footer";
import Portofolio from "../page/Portofolio";
import Blog from "../page/Blog";
import Detail_app from "../page/Detail_app";
import Contact from "../page/Contact";

import Error_page from "../page/Error_page";
import Tecnology_flutter from "../page/Tecnology_flutter";
import Tecnology_reactjs from "../page/Tecnology_reactjs";

const Router = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar menu={menus} />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path={"flutter"} exact element={<Tecnology_flutter />} />
          <Route path={"reactjs"} exact element={<Tecnology_reactjs />} />
          <Route path="/portofolio" exact element={<Portofolio />} />
          <Route path="/blog" exact element={<Blog />} />
          <Route path="/portofolio/detail/:id" exact element={<Detail_app />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="*" element={<Error_page />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </React.Fragment>
  );
};

export default Router;
