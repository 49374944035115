import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Basic_URL from "../config/Basic_url.js";
import { stateAction, stateBlog } from "../../redux/actions/data.action.js";

const Getdata_blog = () => {
  let dispatch = useDispatch();
  const [data, setData] = useState([]);

  const _axios = axios.create({
    baseURL: Basic_URL,
  });

  const getData = async () => {
    try {
      dispatch(
        stateBlog({ isLoading: true, isSuccess: "", isError: "", blog: "" })
      );
      const response1 = await _axios.get(`/blogs`);
      setData(response1);
      const response2 = await _axios.get(`/blog/cards`);
      setData(response2);

      dispatch(
        stateBlog({
          isLoading: false,
          isSuccess: [response1?.status, response2?.status],
          isError: "",
          blog: [response1?.data, response2?.data],
        })
      );
      console.log(response1);
      // dispatch(
      //   stateBlog({
      //     isLoading: false,
      //     isSuccess: "success",
      //     isError: "",
      //     home: home,
      //   })
      // );
    } catch (error) {
      dispatch(
        stateBlog({
          isLoading: false,
          isSuccess: "",
          isError: error?.message,
          blog: "",
        })
      );
      console.log(error?.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return getData;
};
export default Getdata_blog;
