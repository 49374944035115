import { Box, Grid, Skeleton, createTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout_text_content from "../layout/Layout_text_content";
import Portofolio_process from "../components/Portofolio_process";
import Portofolio_rating from "../components/Portofolio_rating";
import Portofolio_test from "../components/Portofolio_test";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  stateAction,
  stateDetail,
  stateHome,
} from "../../controller/redux/actions/data.action";
import axios from "axios";
import Basic_URL from "../../controller/app/config/Basic_url";
import Bad_request_screen from "../layout/model/Bad_request_screen";
import Loader from "../layout/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Client from "../components/Client";
import HeadSection from "../layout/HeadSection";
import ScrollToTop from "../layout/ScrollToTop";

const Detail_app = () => {
  const { isLoading, isError, isSuccess, detail } = useSelector(
    (state) => state.data
  );
  let dispatch = useDispatch();
  const [dataSet, setDataSet] = useState([]);

  const _axios = axios.create({
    baseURL: Basic_URL,
  });

  let { id } = useParams();

  const getData = async () => {
    try {
      dispatch(
        stateDetail({
          isLoading: true,
          isError: "",
          isSuccess: "",
          detail: "",
        })
      );
      const response1 = await _axios.get(`home/section-one/cards/${id}`);

      const response2 = await _axios.get(`/home/customers`);

      const response3 = await _axios.get(`/home/customer/images`);
      setDataSet([response1?.data, response2?.data, response3?.data]);
      dispatch(
        stateDetail({
          isLoading: false,
          isSuccess: [response1?.status, response2?.status, response3?.status],
          isError: "",
          detail: [response1?.data, response2?.data, response3?.data],
        })
      );
      return dataSet;
    } catch (error) {
      dispatch(
        stateDetail({
          isLoading: false,
          isError: error.message,
          isSuccess: "",
          detail: "",
        })
      );
    }
  };

  useEffect(() => {
    getData(id);
  }, []);
  let dataDetail = dataSet;

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  console.log(dataSet[0]?.data?.details, "all");

  if (isLoading) {
    return <Loader />;
  } else if (isError !== "" || isSuccess === "") {
      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "78.7vh",
            }}
          >
            <div style={{ width: "320px" }}>
              <img src="/500.jpg" alt="..." style={{ width: "100%" }} />
            </div>
          </div>
        </>
      );
  } else if (dataDetail[0]?.data?.details.length === 0) {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "78.7vh",
          }}
        >
          <div style={{ width: "320px" }}>
            <img src="/no_data.jpg" alt="..." style={{ width: "100%" }} />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <React.Fragment>
          <ScrollToTop />
          <div className="Detail_app">
            {dataDetail[0]?.data?.details?.map((i, index) => {
              if (index === 0) {
                return (
                  <div>
                    <div
                      className="detail_app"
                      style={{ marginBottom: "30px" }}
                    >
                      <div className="section_hero_portfolio">
                        <div className="container_portfolio">
                          <div
                            className="project_name"
                            style={{ textTransform: "uppercase" }}
                          >
                            {i?.title}
                          </div>
                          <h1 className="h1">{i?.description}</h1>
                        </div>
                      </div>
                    </div>
                    {i?.image_url === null ? (
                      <div></div>
                    ) : (
                      <div class="section_image">
                        <img
                          src={i?.image_url}
                          alt="..."
                          id="img"
                          loading="eager"
                        />

                        {/* <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={600}
                        /> */}
                      </div>
                    )}
                  </div>
                );
              } else if (i?.rates?.length !== 0 && i?.genres?.length === 0) {
                return (
                  <Layout_text_content
                    title={i?.title}
                    desc={
                      <Box
                        sx={{
                          marginBottom: "30px",
                          [theme.breakpoints.up("sm")]: {
                            padding: "0 15%",
                          },
                        }}
                      >
                        {i?.description?.includes("%") ? (
                          <>
                            {`${i?.description?.split("%")[0]} `}
                            <span
                              className="desc12"
                              style={{ fontWeight: "bold" }}
                            >
                              {` ${i?.description?.split("%")[1]} `}
                            </span>
                            {` ${i?.description?.split("%")[2]} `}
                          </>
                        ) : (
                          <>{i?.description}</>
                        )}
                      </Box>
                    }
                    content={
                      <div>
                        <div
                          class="section_image"
                          style={{ marginBottom: "30px" }}
                        >
                          {i?.image_url === null ? (
                            <div></div>
                          ) : (
                            <div class="section_image">
                              <img src={i?.image_url} alt="..." id="img" />
                            </div>
                          )}
                          {i?.second_image_url === null ? (
                            <div></div>
                          ) : (
                            <div
                              class="section_image"
                              style={{ marginBottom: "30px" }}
                            >
                              <img
                                src={i?.second_image_url}
                                alt="..."
                                id="img"
                              />
                            </div>
                          )}
                        </div>
                        <div className="portofolio_rating">
                          <div class="container_stats">
                            <div class="layout_grid">
                              {i?.rates?.map((item) => {
                                return (
                                  <div class="card_stats">
                                    <div class="content_card">
                                      <div
                                        class="number_card"
                                        style={{
                                          alignItems: "center",
                                          display: "flex",
                                          gap: "10px",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <img
                                          src={item?.image_url}
                                          alt=""
                                          style={{
                                            width: "40px",
                                            borderRadius: "8px",
                                          }}
                                        />
                                        {`${item?.number}`}
                                      </div>
                                      <div class="title_card">
                                        {item?.description}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                );
              } else if (i?.genres.length !== 0 && i?.rates?.length === 0) {
                return (
                  <div>
                    <div
                      className="detail_app"
                      style={{ marginBottom: "30px" }}
                    >
                      <div className="detail_item">
                        <div className="div_titre_gauche">
                          <h2 className="h2">{i?.title}</h2>
                          <p className="paragraphe_portfolio">
                            {/* {i?.description} */}

                            {/* {`${i?.description?.split("%")[0]} `}
                            <span
                              className="desc12"
                              style={{ fontWeight: "bold" }}
                            >
                              {` ${i?.description?.split("%")[1]} `}
                            </span>
                            {` ${i?.description?.split("%")[2]} `} */}

                            {i?.description?.includes("%") ? (
                              <>
                                {`${i?.description?.split("%")[0]} `}
                                <span
                                  className="desc12"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {` ${i?.description?.split("%")[1]} `}
                                </span>
                                {` ${i?.description?.split("%")[2]} `}
                              </>
                            ) : (
                              <>{i?.description}</>
                            )}
                          </p>
                        </div>
                        <div className="div_droit">
                          <Grid
                            container
                            spacing={{ xs: 2, md: 1 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            alignItems="center"
                            justifyContent="center"
                          >
                            {i?.genres?.map((item) => {
                              return (
                                <Grid item xs={2} sm={2} md={6}>
                                  <div className="card_detail">
                                    {item?.genre?.image_url === null ? (
                                      <div></div>
                                    ) : (
                                      <img
                                        src={item?.genre?.image_url}
                                        alt=""
                                        id="img"
                                      />
                                    )}

                                    <p className="desc">
                                      {item?.genre?.designation}
                                    </p>
                                  </div>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </div>
                      </div>
                    </div>
                    {i?.image_url === null ? (
                      <div></div>
                    ) : (
                      <div
                        class="section_image"
                        style={{ marginBottom: "30px" }}
                      >
                        <img src={i?.image_url} alt="..." id="img" />
                      </div>
                    )}
                    {i?.second_image_url === null ? (
                      <div></div>
                    ) : (
                      <div class="section_image">
                        <img src={i?.second_image_url} alt="..." id="img" />
                      </div>
                    )}
                  </div>
                );
              } else if (i?.genres.length !== 0 && i?.rates?.length !== 0) {
                return (
                  <div>
                    <div
                      className="detail_app"
                      style={{ marginBottom: "30px" }}
                    >
                      <div className="detail_item">
                        <div className="div_titre_gauche">
                          <h2 className="h2">{i?.title}</h2>
                          <p className="paragraphe_portfolio">
                            {/* {i?.description} */}

                            {i?.description?.includes("%") ? (
                              <>
                                {`${i?.description?.split("%")[0]} `}
                                <span
                                  className="desc12"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {` ${i?.description?.split("%")[1]} `}
                                </span>
                                {` ${i?.description?.split("%")[2]} `}
                              </>
                            ) : (
                              <>{i?.description}</>
                            )}
                          </p>
                        </div>
                        <div className="div_droit">
                          <Grid
                            container
                            spacing={{ xs: 2, md: 1 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            alignItems="center"
                            justifyContent="center"
                          >
                            {i?.genres?.map((item) => {
                              return (
                                <Grid item xs={2} sm={2} md={6}>
                                  <div className="card_detail">
                                    {item?.genre?.image_url === null ? (
                                      <div></div>
                                    ) : (
                                      <img
                                        src={item?.genre?.image_url}
                                        alt=""
                                        id="img"
                                      />
                                    )}
                                    <p className="desc">
                                      {item?.genre?.designation}
                                    </p>
                                  </div>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </div>
                      </div>
                    </div>
                    {i?.image_url === null ? (
                      <div></div>
                    ) : (
                      <div
                        class="section_image"
                        style={{ marginBottom: "30px" }}
                      >
                        <img src={i?.image_url} alt="..." id="img" />
                      </div>
                    )}
                    {i?.second_image_url === null ? (
                      <div></div>
                    ) : (
                      <div
                        class="section_image"
                        style={{ marginBottom: "30px" }}
                      >
                        <img src={i?.second_image_url} alt="..." id="img" />
                      </div>
                    )}
                    <div
                      className="portofolio_rating"
                      style={{ marginBottom: "120px" }}
                    >
                      <div class="container_stats">
                        <div class="layout_grid">
                          {i?.rates?.map((item) => {
                            return (
                              <div class="card_stats">
                                <div class="content_card">
                                  <div
                                    class="number_card"
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      gap: "10px",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <img
                                      src={item?.image_url}
                                      alt=""
                                      style={{
                                        width: "40px",
                                        borderRadius: "8px",
                                      }}
                                    />
                                    {`${item?.number}`}
                                  </div>
                                  <div class="title_card">
                                    {item?.description}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div>
                    <div className="process">
                      <div class="container_titre_center">
                        <Box
                          sx={{
                            [theme.breakpoints.up("sm")]: {
                              padding: "0 10%",
                            },
                          }}
                        >
                          <h2 class="h2">{i?.title}</h2>
                          <p class="paragraphe">
                            {/* {i?.description} */}
                            {i?.description?.includes("%") ? (
                              <>
                                {`${i?.description?.split("%")[0]} `}
                                <span
                                  className="desc12"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {` ${i?.description?.split("%")[1]} `}
                                </span>
                                {` ${i?.description?.split("%")[2]} `}
                              </>
                            ) : (
                              <>{i?.description}</>
                            )}
                          </p>
                        </Box>
                      </div>
                    </div>
                    {i?.image_url === null ? (
                      <div></div>
                    ) : (
                      <div class="section_image">
                        <img src={i?.image_url} alt="..." id="img" />
                      </div>
                    )}
                    {i?.second_image_url === null ? (
                      <div></div>
                    ) : (
                      <div class="section_image">
                        <img src={i?.second_image_url} alt="..." id="img" />
                      </div>
                    )}
                  </div>
                );
              }
            })}

            {/* <div class="section_image">
            <img
              loading="lazy"
              src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/636930f0d63c3cdd0d92bc7c_Image%20fw.jpg"
              alt="..."
              id="img"
            />
          </div>
          <div className="process">
            <div class="container_titre_center">
              <h2 class="h2">Process &amp; méthodologie</h2>
              <p class="paragraphe">
                Le process et la méthodologie permettent de mener à bien un
                projet de manière efficace et organisée pour minimiser les
                risques d'échec et optimiser les résultats.
              </p>
            </div>
            <div class="section_image">
              <img
                loading="lazy"
                src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/63690f756516f90873515cb9_PROCESS.png"
                alt="..."
                id="img"
              />
            </div>
          </div>
          <div className="detail_app">
            <div className="process">
              <div class="container_titre_center">
                <h2 class="h2">Analyse et User Flow</h2>
                <p class="paragraphe">
                  L'analyse et le user flow sont des étapes clés pour comprendre
                  les besoins des utilisateurs et créer une application facile à
                  utiliser.
                </p>
              </div>
            </div>
            <div class="section_image">
              <img
                loading="lazy"
                src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/6369024a45bc95a1229c42aa_USERFLOW.png"
                alt="..."
                id="img"
              />
            </div>
          </div>
          <div className="process">
            <div class="container_titre_center">
              <h2 class="h2">Analyse et User Flow</h2>
              <p class="paragraphe">
                L'analyse et le user flow sont des étapes clés pour comprendre
                les besoins des utilisateurs et créer une application facile à
                utiliser.
              </p>
            </div>
          </div>
          <div class="section_image">
            <img
              loading="lazy"
              src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/636d12052b7079458e2d9cdb_WIREFRAME2-p-2600.png"
              alt="..."
              id="img"
            />
          </div>
          <div className="detail_app">
            <div className="process">
              <div class="container_titre_center">
                <h2 class="h2">Maquettes et UI Design</h2>
                <p class="paragraphe">
                  Création et intégration de la charte graphique pour créer une
                  expérience utilisateur agréable et intuitive pour les
                  utilisateurs en utilisant des éléments visuels cohérents et en
                  respectant les normes et les conventions de l'industrie.
                </p>
              </div>
            </div>
            <div class="section_image">
              <img
                loading="lazy"
                src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/636903b1d43386308aabdbeb_WAC1.png"
                alt="..."
                id="img"
              />
            </div>
            <div class="section_image">
              <img
                loading="lazy"
                src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/636903b1272847ab28e0988a_WAC2.png"
                alt="..."
                id="img"
              />
              <img
                loading="lazy"
                src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/636903b22686f824ac6376ce_WAC3.png"
                alt="..."
                id="img"
              />
              <img
                loading="lazy"
                src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/636903b11303a56658dc1d58_WAC4.png"
                alt="..."
                id="img"
              />
            </div>
          </div> */}
            {/* <div className="detail_app">
            <div className="process">
              <div class="container_titre_center">
                <h2 class="h2">Typographie et Couleurs</h2>
                <p class="paragraphe">
                  La charte graphique doit être cohérente avec la marque de
                  l'application pour créer une expérience utilisateur agréable
                  et intuitive.
                </p>
              </div>
            </div>
            <div class="section_image">
              <img
                loading="lazy"
                src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/63690375e5e836faac8e52fc_TYPOCOULEUR.png"
                alt="..."
                id="img"
              />
            </div>
          </div> */}
            {/* <div class="section_image">
            <img
              loading="lazy"
              src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/636d1366a62e0685bdafb988_MAQUETTES-p-3200.png"
              alt="..."
              id="img"
            />
          </div> */}
            {/* <Layout_text_content
            title="Process de réalisation"
            desc="Comme toutes nos réalisations, nous avons travaillé en méthode agile, nous permettant d'être proche du client, de s'adapter aux changements et de faire évoluer le projet en fonction des besoins et des retours en continu."
            content={<Portofolio_process />}
          />
          <Layout_text_content
            title="Une collaboration de long terme entre We Are Caring et Apparence"
            desc="Nous sommes ravis d'accompagner We Are Caring sur le long terme, pour connecter les employeurs et les candidats de manière responsable et inclusive. Ce projet est en accord avec nos valeurs et nous sommes heureux de pouvoir contribuer à sa réussite."
            content={<Portofolio_rating />}
          /> */}
            {/* <div class="section_image">
            <img
              loading="lazy"
              src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/636d163f04bd2d05449fd2e0_WAC8-p-3200.png"
              alt="..."
              id="img"
            />
          </div> */}
            <div className="detail_app">
              <div className="client">
                {dataSet[1]?.data?.data?.map((item) => {
                  return (
                    <HeadSection
                      label={item?.label}
                      title={item?.title}
                      description={item?.description}
                      textAlign="center"
                    />
                  );
                })}

                <div className="client_grid">
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 5, sm: 10, md: 16 }}
                    alignContent="center"
                    justifyContent="center"
                  >
                    {dataSet[2]?.data?.data?.map((item) => {
                      //image_url
                      return (
                        <Grid item xs={2}>
                          <div>
                            <img
                              src={item.image_url}
                              alt="..."
                              className="img"
                            />
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </div>
            </div>

            <Portofolio_test dataTestemony={dataSet[0]?.data?.testimonies} />
          </div>
        </React.Fragment>
      </>
    );
  }
};

export default Detail_app;
