import { Button, Grid } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Getdata_blog from "../../controller/app/data/Getdata_blog";
import { useSelector } from "react-redux";
import Bad_request_screen from "../layout/model/Bad_request_screen";
import Loader from "../layout/Loader";
import dayjs from "dayjs";
import Layout_onboarding_side from "../layout/Layout_onboarding_side";
import Layout_onboarding_side_without_btn from "../layout/Layout_onboarding_side_without_btn";
import Layout_onboarding_side_page from "../layout/Layout_onboarding_side_page";

const Blog = () => {
  Getdata_blog();

  const { blog, isLoading, isError } = useSelector((state) => state.data);
  const [page, setPage] = React.useState(blog[1]?.data?.meta?.current_page);
  Getdata_blog();
  const handleChange = (event, value) => {
    setPage(value);
  };
  console.log(blog);

  if (isLoading) {
    return <Loader />;
  } else if (isError !== "") {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "78.7vh",
          }}
        >
          <div style={{ width: "320px" }}>
            <img src="/500.jpg" alt="..." style={{ width: "100%" }} />
          </div>
        </div>
      </>
    );
  } else if (
    blog[0]?.data?.data.length === 0 ||
    blog[1]?.data?.data.length === 0
  ) {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "78.7vh",
          }}
        >
          <div style={{ width: "320px" }}>
            <img src="/no_data.jpg" alt="..." style={{ width: "100%" }} />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="Blog">
          {/* <div className="onboard_Blog"> */}
          {/* <img
              className="img1"
              src="/blog.webp"
              alt="..."
            />
            <img
              className="img2"
              src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/62615dfba29b3b0fb8ed885b_illustration_hero_home_left.svg"
              alt="..."
            /> */}
          <>
            {blog[0]?.data?.data?.map((i) => {
              return (
                // <Layout_onboarding_side
                //   title={i?.title}
                //   description={i?.description}
                //   btn={i?.bt_designation}
                //   tag="home"
                //   action="#app"
                //   img1="/blog.webp"
                // />
                <Layout_onboarding_side_page
                  title={i?.title}
                  description={i?.description}
                  btn={i?.bt_designation}
                  tag="home"
                  action="#app"
                  img1="/blog.webp"
                />
              );
            })}
          </>
          {/* </div> */}
          <div className="blog">
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {blog[1]?.data?.data?.map((item, index) => {
                return (
                  <Grid item xs={4}>
                    <a
                      className="testmony_item"
                      href={item?.url}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="img_test">
                        {item?.image_url === null ? (
                          <div
                            style={{ background: "grey" }}
                            className="img"
                          ></div>
                        ) : (
                          <img
                            src={item?.image_url}
                            alt="..."
                            className="img"
                          />
                        )}
                      </div>
                      <div className="desc">
                        {/* <div className="desc_title">{item.name}</div> */}
                        <div className="subtitle">
                          <p
                            style={{
                              margin: 0,
                              padding: 0,
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {`${item?.title}`}
                            <div
                              style={{
                                fontSize: "12px",
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                // backgroundColor: item?.type?.color,
                                padding: "0px ",
                                borderRadius: "30px",
                                color: item?.type?.color,
                                width: "50px",
                                justifyContent: "center",
                              }}
                            >
                              {item?.type?.image === null ? (
                                <div></div>
                              ) : (
                                <img
                                  src={item?.type?.image}
                                  alt="..."
                                  style={{ width: "14px", height: "14px" }}
                                />
                              )}
                              <p
                                style={{ margin: 0, p: 0, fontSize: "10px" }}
                              >{`${item?.type?.designation}`}</p>
                            </div>
                          </p>
                        </div>
                        <div className="desc_text">
                          <p>{item?.description}</p>
                        </div>
                        <div className="desc_text_span">
                          <div
                            style={{
                              gap: "7px",
                              display: "flex",
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "black",
                              alignItems: "center",
                            }}
                          >
                            <p style={{ margin: 0, padding: 0 }}>Publié</p>{" "}
                            <span>
                              {dayjs(item?.created_at).format(
                                "Le DD MM YYYY, h:mm:ss"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </Grid>
                );
              })}
            </Grid>

            <div
              className="pagination"
              style={{
                margin: "50px auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack spacing={2}>
                <Pagination
                  count={blog[1]?.data?.meta?.current_page}
                  page={blog[1]?.data?.meta?.first_page}
                  onChange={handleChange}
                />
              </Stack>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Blog;
