import React from "react";
import Portofolio_content from "../components/Portofolio_content";
import Portofolio_client from "../components/Portofolio_client";
import Layout_onboarding_side from "../layout/Layout_onboarding_side";
import { useSelector } from "react-redux";
import Getdata_portofolio from "../../controller/app/data/Getdata_portofolio";
import Loader from "../layout/Loader";
import Bad_request_screen from "../layout/model/Bad_request_screen";

import Layout_onboarding_side_page from "../layout/Layout_onboarding_side_page";
import Testmony from "../components/Testmony";

const Portofolio = () => {
  Getdata_portofolio();
  const { porto, isLoading, isError, isSuccess } = useSelector(
    (state) => state.data
  );

  let data_section_one = porto[0]?.data?.data;
  console.log(data_section_one);
  if (isLoading === true) {
    return <Loader />;
  } else if (isError !== "") {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "78.7vh",
          }}
        >
          <div style={{ width: "320px" }}>
            <img src="/500.jpg" alt="..." style={{ width: "100%" }} />
          </div>
        </div>
      </>
    );
  } else if (data_section_one?.length === 0 && isError === "") {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "78.7vh",
          }}
        >
          <div style={{ width: "320px" }}>
            <img src="/no_data.jpg" alt="..." style={{ width: "100%" }} />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="Tecno">
          {data_section_one?.map((item) => {
            return (
              <Layout_onboarding_side_page
                title={item?.title}
                description={item?.description}
                // btn={item?.bt_designation}
                tag="Portofolio"
                action="#app"
                img1=""
              />
            );
          })}
          <div className="tecno">
            <Portofolio_content />
            <Portofolio_client />
            <div style={{ marginTop: "120px" }}>
              <Testmony />
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Portofolio;
