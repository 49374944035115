import { Grid, createTheme } from "@mui/material";
import React, { useState } from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { useSelector } from "react-redux";
import HeadSection from "../layout/HeadSection";
let selectedIndex = 0;
const Faq_flutter = () => {
  const [expanded, setExpanded] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const { tech } = useSelector((state) => state.data);
  let section_faq = tech[4]?.data?.data;
  let section_faq_content = tech[5]?.data?.data;
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  const Mondel = ({ id, title, text, index }) => {
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{ textAlign: "start", marginBottom: "20px" }}
      >
        <div className="quest">
          <h3
            style={{
              padding: "8px 15px",
              lineHeight: "1.5rem",
              fontSize: "18px",
            }}
          >
            {title}
          </h3>
        </div>
        <div className="resp">
          <p
            style={{
              padding: "0 15px",
              lineHeight: "1.5rem",
              fontSize: "14px",
            }}
          >
            {text}
          </p>
        </div>
      </Grid>
    );
  };
  return (
    <div
      className="faq"
      style={{
        fontFamily: "Montserrat",
      }}
    >
      {section_faq?.map((item) => {
        return (
          <div className="faq_item">
            <div>
              <Grid
                container
                spacing={{ xs: 5, md: 6 }}
                // columns={{ xs: 2, sm: 2, md: 2 }}
                alignItems="center"
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    flexDirection: "column-reverse",
                  },
                }}
              >
                <Grid item xs={12} sm={12} md={6} sx={{ textAlign: "start" }}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    // columns={{ xs: 2, sm: 2, md: 2 }}
                  >
                    <div>
                      <HeadSection
                        label={item?.label}
                        title={item?.title}
                        textAlign="start"
                        padding_mobile="0 30px"
                      />
                      <>
                        {section_faq_content.map((i, index) => {
                          if (item?.id === i?.faq_section_id)
                            return (
                              <Mondel
                                title={i?.title}
                                text={i?.description}
                                index={index}
                              />
                            );
                        })}
                      </>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  // sx={{
                  //   [theme.breakpoints.down("sm")]: {
                  //     display: "none",
                  //   },
                  // }}
                >
                  <img src={item?.image_url} className="img_faq" />
                </Grid>
              </Grid>
            </div>

            {/* <div className="item_img">
          <img src="/coding-man.jpg" alt="..." id="img" />
        </div> */}
          </div>
        );
      })}
    </div>
  );
};
export default Faq_flutter;
