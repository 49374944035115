import React, { useState } from "react";
import Layout_onboarding_side_without_btn from "../layout/Layout_onboarding_side_without_btn";
import { Box, Button, Fade, Grid, createTheme } from "@mui/material";
import Map from "../layout/Map";
import HeadSection from "../layout/HeadSection";
import Btn_without_bg from "../layout/model/Btn_without_bg";
import { useDispatch, useSelector } from "react-redux";
import { stateSend } from "../../controller/redux/actions/data.action";
import Basic_URL from "../../controller/app/config/Basic_url";
import axios from "axios";
import { toast } from "react-toastify";

const Contact = () => {
  const [name, setName] = useState("");
  const [entreprise, setEntreprise] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [need, setNeed] = useState("");
  const [budget, setBudget] = useState("");
  const [desc, setDesc] = useState("");
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.data);
  const _axios = axios.create({
    // baseURL: Basic_URL,
    data: {
      names: name,
      entreprise: entreprise,
      email: email,
      tel: tel,
      need: need,
      budget: budget,
      description: desc,
    },
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
  });

  const handleSend = async (e) => {
    e.preventDefault();

    if (name === "") {
      toast.error("Veillez mettre votre noms");
      return;
    }
    if (email === "") {
      toast.error("Veillez mettre votre adress mail");
      return;
    }
    if (tel === "") {
      toast.error("Veillez mettre votre numéro de téléphone");
      return;
    }
    dispatch(
      stateSend({ isLoading: true, isError: "", isSuccess: "", sendForm: "" })
    );

    try {
      // make axios post request

      const response = await axios.post(`${Basic_URL}/customer/contact`, {
        names: name,
        enterprise: entreprise,
        email: email,
        tel: tel,
        need: need,
        budget: budget,
        description: desc,
      });
      dispatch(
        stateSend({
          isLoading: false,
          isError: "",
          isSuccess: "success",
          sendForm: response.message,
        })
      );
      toast.success("votre formulaire a été envoyer avec success");
      setName("");
      setEntreprise("");
      setEmail("");
      setNeed("");
      setBudget("");
      setDesc("");
    } catch (error) {
      dispatch(
        stateSend({
          isLoading: false,
          isError: error.message,
          isSuccess: "",
          sendForm: "",
        })
      );
      toast.error(error.message);
    }
  };
  let tabContent = [
    {
      background: "",
      img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/642feb9429bc38615b4ac584_Tel.png",
      title: "Appelez-nous",
      desc: "+243 973 959 9675",
      link: "tel:+243973959675",
    },
    {
      background: "",
      img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/642feb944451d7267635ec11_Mail.png",
      title: "Ecrivez-nous",
      desc: "tech@upper-z.com",
      link: "mailto:tech@upper-z.com",
    },
    {
      background: "",
      img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/642feb94bc3b958106758edc_Lieu.png",
      title: "Venez nous voir",
      desc: "Av. des enseignants, com. de Goma, ville de Goma ou visitez le map",
      link: "https://maps.app.goo.gl/oX4vqdZmNfPbBbPU9?g_st=ic",
    },
  ];
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  return (
    <div className="Contact">
      <Layout_onboarding_side_without_btn
        title="Laissez-nous vous aider à concrétiser votre projets."
        description="Notre équipe est à votre disposition 24h/7 pour discuter de votre projet et trouver la solution qui vous convient."
        btn="Voir nos realisation"
      />
      <div className="contact">
        <Grid
          container
          spacing={{ xs: 5, md: 6 }}
          // columns={{ xs: 2, sm: 2, md: 2 }}
          // alignItems="center"
        >
          <Grid item xs={12} sm={12} md={6} sx={{ textAlign: "start" }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              // columns={{ xs: 2, sm: 2, md: 2 }}
            >
              <div>
                <HeadSection
                  label="Nous contacter"
                  title="Prenez rendez-vous avec un de, nos experts"
                  textAlign="start"
                  padding_mobile="0 30px"
                  textAlign_mobile="center"
                />
                <Box
                  className="item_one"
                  sx={{
                    padding: "0px 15px",
                    lineHeight: "1.5rem",
                    fontSize: "18px",
                    margin: "0",
                    [theme.breakpoints.down("sm")]: {
                      padding: "0px 30px",
                    },
                  }}
                >
                  <div className="container_contact">
                    <form
                      data-name="contact-form-upperz"
                      method="post"
                      className="form"
                      aria-label="contact-form"
                    >
                      <div className="line_contact">
                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 4, md: 12 }}
                        >
                          <Grid item xs={4} md={6} sm={2}>
                            <div className="info_contact">
                              <label for="name" className="field_label">
                                Noms complet
                              </label>
                              <input
                                type="text"
                                className="input_form"
                                maxlength="256"
                                name="name"
                                data-name="Name"
                                placeholder="Entrez votre noms complets"
                                id="name"
                                required=""
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={4} md={6} sm={2}>
                            <div className="info_contact">
                              <label for="company" className="field_label">
                                Entreprise
                              </label>
                              <input
                                type="text"
                                className="input_form"
                                maxlength="256"
                                name="Company"
                                data-name="Company"
                                placeholder="Entrez votre entreprise"
                                id="company"
                                required=""
                                onChange={(e) => {
                                  setEntreprise(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>

                      <div className="line_contact">
                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 4, md: 12 }}
                        >
                          <Grid item xs={4} md={6} sm={2}>
                            <div className="info_contact">
                              <label for="email" className="field_label">
                                Email
                              </label>
                              <input
                                type="email"
                                className="input_form"
                                maxlength="256"
                                name="email"
                                data-name="Email"
                                placeholder="Entrez votre adresse mail"
                                id="email"
                                required=""
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={4} md={6} sm={2}>
                            <div className="info_contact">
                              <label for="phone" className="field_label">
                                Téléphone
                              </label>
                              <input
                                type="tel"
                                className="input_form"
                                maxlength="256"
                                name="Phone"
                                data-name="Phone"
                                placeholder="Numéro avec code pays (ex.+243)"
                                id="phone"
                                required=""
                                onChange={(e) => {
                                  setTel(e.target.value);
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="line_contact">
                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 4, md: 12 }}
                        >
                          <Grid item xs={4} md={6} sm={2}>
                            <div className="info_contact">
                              <label for="why" className="field_label">
                                Votre besoin
                              </label>
                              <select
                                id="why"
                                name="Why"
                                data-name="Why"
                                required=""
                                className="select_field"
                                defaultValue="Je souhaite parler de mon projet"
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                                onChange={(e) => {
                                  setNeed(e.target.value);
                                }}
                              >
                                <option
                                  value="Je souhaite parler de mon projet"
                                  className="option"
                                >
                                  Je souhaite parler de mon projet
                                </option>
                                <option
                                  value="Postuler chez Upperz"
                                  className="option"
                                >
                                  Postuler chez Upperz
                                </option>
                                <option
                                  value="Simple prise de contact"
                                  className="option"
                                >
                                  Simple prise de contact
                                </option>
                                <option value="Autre" className="option">
                                  Autre
                                </option>
                              </select>
                            </div>
                          </Grid>
                          <Grid item xs={4} md={6} sm={2}>
                            <div className="info_contact">
                              <label for="budget" className="field_label">
                                Votre budget
                              </label>
                              <select
                                id="budget"
                                name="Budget"
                                data-name="Budget"
                                required=""
                                className="select_field"
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                                onChange={(e) => {
                                  setBudget(e.target.value);
                                }}
                              >
                                <option value="<10 000$" className="option">
                                  &lt; 10 000$
                                </option>
                                <option value="<20 000$" className="option">
                                  &lt; 20 000$
                                </option>
                                <option
                                  value="Entre 20 000$ et 50 000$"
                                  className="option"
                                >
                                  Entre 20 000$ et 50 000$
                                </option>
                                <option
                                  value="Entre 50 000$ et 100 000$"
                                  className="option"
                                >
                                  Entre 50 000$ et 100 000$
                                </option>
                                <option value=">100 000$" className="option">
                                  &gt; 100 000$
                                </option>
                              </select>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="line_contact">
                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                          <Grid item xs={12}>
                            <div className="info_contact">
                              <label for="description" className="field_label">
                                Décrivez-nous votre projet
                              </label>
                              <textarea
                                id="description"
                                name="Project"
                                maxlength="5000"
                                data-name="Project"
                                placeholder="Aidez nous à comprendre votre projet en nous donnant une brève description de votre projet"
                                required=""
                                className="input_form"
                                style={{
                                  fontFamily: "Montserrat",
                                  paddingTop: "12px",
                                  paddingBottom: "12px",
                                  paddingLeft: "10px",
                                  // paddingRight: "10px",
                                }}
                                onChange={(e) => {
                                  setDesc(e.target.value);
                                }}
                              ></textarea>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="line_button">
                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 4, sm: 4, md: 12 }}
                          alignItems="flex-start"
                          justifyContent="flex-start"
                        >
                          <Grid item xs={6} md={6} sm={12}>
                            {/* <>
                              {isLoading === true ? (
                                <div className="button_right">
                                  <div className="custom-loader"></div>
                                </div>
                              ) : (
                                <Button
                                  // type="submit"
                                  // id="submit-form-contact"
                                  // isLoading="En cours..."
                                  className="button_right"
                                  onClick={handleSend}
                                >
                                  Envoyer
                                </Button>
                              )}
                            </> */}
                            <Button
                              // type="submit"
                              // id="submit-form-contact"
                              // isLoading="En cours..."
                              className="button_right"
                              onClick={handleSend}
                              sx={{ textAlign: "center" }}
                            >
                              {isLoading === true ? (
                                <div className="custom-loader"></div>
                              ) : (
                                "Envoyer"
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </form>
                  </div>
                </Box>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
            }}
          >
            <img
              src="/contact.webp"
              className="img_faq"
              style={{
                width: "100%",
                height: "650px",
                textAlign: " left",
                objectFit: "cover",
                borderRadius: " 8px",
                display: "inline-block",
              }}
            />
          </Grid>
        </Grid>

        <div className="item_two">
          <div className="onboarding_item_two">
            <div className="title_expertise">CONTACTS DIRECTS</div>
            <div className="desc_expertise">
              <div className="desc1">
                <div className="desc11">
                  Contactez-nous dès maintenant pour commencer
                </div>
              </div>
            </div>
          </div>

          <div className="expertise_grid">
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {tabContent?.map((i) => {
                return (
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    <div
                      style={{
                        marginBottom: "20px",
                      }}
                    >
                      <a
                        className="card_grid"
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                        href={i?.link}
                      >
                        <div className="img_expertise">
                          <img
                            src={i?.img}
                            alt="..."
                            id="img"
                            style={{ width: "100px", marginBottom: "10px" }}
                          />
                        </div>
                        <div className="content_expertise">
                          <h1 style={{ fontSize: "16px", margin: "0px 0" }}>
                            {i?.title}
                          </h1>
                          <p
                            style={{
                              fontSize: "14px",
                              lineHeight: "1.5rem",
                              margin: "0",
                            }}
                          >
                            {i?.desc}
                          </p>
                        </div>
                      </a>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
        {/* <Map /> */}
      </div>
    </div>
  );
};

export default Contact;
