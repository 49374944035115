import React from "react";

const Layout_text_content = ({ title, desc, content }) => {
  return (
    <div className="layout_text_content">
      <div className="head">
        <div class="container_titre_center">
          <h2 class="h2">{title}</h2>
          <p class="paragraphe">{desc}</p>
        </div>
      </div>
      <div class="section_content">{content}</div>
    </div>
  );
};

export default Layout_text_content;
