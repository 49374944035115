import { Box, Button, createTheme } from "@mui/material";
import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import Btn_without_bg from "./model/Btn_without_bg";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
const Layout_onboarding_side = ({
  title,
  description,
  btn,
  action,
  tag,
  img1,
  img_christ,
  // img2,
  // img3,
}) => {
  return (
    <div className="onboard_side">
      {/* <img
        className="img3"
        // src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/62615dfb208faa6739c9f17e_illustration_hero_home_right.svg"
        src={img3}
        alt="..."
      /> */}
      {img1 === "" ? (
        <></>
      ) : (
        <img
          className="img1"
          // src="/landing_img.webp"
          src={img1}
          // src={img_christ}
          alt="..."
        />
      )}

      {/* <img
        className="img2"
        // src="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/62615dfba29b3b0fb8ed885b_illustration_hero_home_left.svg"
        src={img2}
        alt="..."
      /> */}
      <div className="onboard_title">
        <h1>{title}</h1>
      </div>
      <div className="onboard_desc">
        <Box
          sx={{
            // sm: { paddingLeft: padding_mobile },
            // paddingLeft: { sm: padding_mobile },

            [theme.breakpoints.up("sm")]: {
              padding: "0 100px",
              // width: "80%",
            },
          }}
        >
          <p>{description}</p>
        </Box>
      </div>
      {/* 
      <Button
        className="onboard_btn"
        sx={{ fontFamily: "Montserrat" }}
        href={action}
      >
        {btn}
        <MdKeyboardArrowDown fontSize={18} />
      </Button> */}
      <Btn_without_bg value={btn} action={action} />
    </div>
  );
};

export default Layout_onboarding_side;
