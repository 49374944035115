import { Box, Button, createTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import HeadSection from "../layout/HeadSection";

const Presentation_flutter = () => {
  const { tech } = useSelector((state) => state.data);
  let data_expertise = tech;
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  return (
    <>
      {data_expertise?.map((item, index) => {
        if (index === 3) {
          return (
            <>
              {item?.data?.data?.map((i) => {
                return (
                  <div className="span_expertise">
                    <div className="header_section_vertical">
                      <img
                        srcset={
                          i?.image_url === null
                            ? "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/62615fff03b3f64673624921_icon_flutter_cocorico-p-500.png 500w, https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/62615fff03b3f64673624921_icon_flutter_cocorico.png 528w"
                            : i?.image_url
                        }
                        loading="lazy"
                        width="428"
                        height="98"
                        // srcset="https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/62615fff03b3f64673624921_icon_flutter_cocorico-p-500.png 500w, https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/62615fff03b3f64673624921_icon_flutter_cocorico.png 528w"
                        alt="expertise"
                        className="img"
                      />
                      <Box
                        sx={{
                          [theme.breakpoints.up("sm")]: {
                            padding: "0 15%",
                          },
                        }}
                      >
                        <HeadSection
                          label={i?.label}
                          title={i?.title}
                          description={i?.description}
                          textAlign="center"
                        />
                      </Box>

                      <div className="btn_project">
                        <Button id="btn_project" href="/portofolio">
                          {i?.bt_designation === null
                            ? "Voir nos projets"
                            : i?.bt_designation}
                          {/* {item?.bt_designation} */}
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          );
        }
      })}
    </>
  );
};

export default Presentation_flutter;
