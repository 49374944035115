import React from "react";
import Presentation_flutter from "../components/Presentation_flutter";
import Portofolio_flutter from "../components/Portofolio_flutter";
import Client from "../components/Client";
import Faq_flutter from "../components/Faq_flutter";
import Layout_onboarding_side from "../layout/Layout_onboarding_side";
import Getdata_technology from "../../controller/app/data/Getdata_technology";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../layout/Loader";
import Bad_request_screen from "../layout/model/Bad_request_screen";
import Layout_onboarding_side_page from "../layout/Layout_onboarding_side_page";
import Testmony from "../components/Testmony";
const Tecnology_reactjs = () => {
  Getdata_technology("React Js");
  const { data, isLoading, isError, tech } = useSelector((state) => state.data);

  let { id } = useParams();
  // new branch dev
  let data_section_one = tech[0]?.data?.data;
  if (isLoading) {
    return <Loader />;
  } else if (isError !== "" || tech === "") {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "78.7vh",
          }}
        >
          <div style={{ width: "320px" }}>
            <img src="/500.jpg" alt="..." style={{ width: "100%" }} />
          </div>
        </div>
      </>
    );
  } else if (data_section_one?.length === 0) {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "78.7vh",
          }}
        >
          <div style={{ width: "320px" }}>
            <img src="/no_data.jpg" alt="..." style={{ width: "100%" }} />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        {isLoading === true ? (
          <Loader />
        ) : isError !== "" && data === "" ? (
          <Bad_request_screen />
        ) : (
          <div className="Tecno">
            {data_section_one?.map((item) => {
              return (
                <Layout_onboarding_side_page
                  title={item?.title}
                  description={item?.description}
                  btn={item?.bt_designation}
                  tag="home"
                  action="#app"
                  img1=""
                />
              );
            })}
            <Presentation_flutter />
            <Portofolio_flutter />

            <div className="tecno">
              <Faq_flutter />
            </div>

            <div className="tecno">
              <Client />
              <div style={{ marginTop: "120px" }}>
                <Testmony />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};

export default Tecnology_reactjs;
