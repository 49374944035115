import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import HeadSection from "../layout/HeadSection";

const Client = () => {
  const { home } = useSelector((state) => state.data);
  let section_client = home[7]?.data?.data;
  let section_client_img = home[8]?.data?.data;
  // let tabClient = [
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef998e4ed8ca1a8bf15_logo-we-are-caring.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef509651673f824f14c_logo-monsuividiet.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef7dec552b5b83fa079_logo-roche.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef5c85e86cf3657a94d_logo-inria.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef0baac251d41905511_logo-alumforce.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/6448c1adc5dd97ca37588531_PHOTOMATON.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef0b3f56270c96b493d_logo-avalun.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef998e4ed8ca1a8bf15_logo-we-are-caring.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef509651673f824f14c_logo-monsuividiet.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef7dec552b5b83fa079_logo-roche.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef5c85e86cf3657a94d_logo-inria.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef0baac251d41905511_logo-alumforce.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/6448c1adc5dd97ca37588531_PHOTOMATON.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef0b3f56270c96b493d_logo-avalun.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef998e4ed8ca1a8bf15_logo-we-are-caring.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef509651673f824f14c_logo-monsuividiet.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef7dec552b5b83fa079_logo-roche.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef5c85e86cf3657a94d_logo-inria.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef0baac251d41905511_logo-alumforce.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/6448c1adc5dd97ca37588531_PHOTOMATON.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef0b3f56270c96b493d_logo-avalun.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef998e4ed8ca1a8bf15_logo-we-are-caring.png",
  //   },
  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef509651673f824f14c_logo-monsuividiet.png",
  //   },

  //   {
  //     img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/623b4ef0b3f56270c96b493d_logo-avalun.png",
  //   },
  // ];

  return (
    <div className="client">
      {section_client?.map((item) => {
        return (
          <HeadSection
            label={item?.label}
            title={item?.title}
            description={item?.description}
            textAlign="center"
          />
        );
      })}

      <div className="client_grid">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 5, sm: 10, md: 16 }}
          alignContent="center"
          justifyContent="center"
        >
          {section_client_img?.map((item) => {
            //image_url
            return (
              <Grid item xs={2}>
                <div>
                  <img src={item.image_url} alt="..." className="img" />
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default Client;
