import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container_footer">
        <div className="haut_content">
          <div className="img_footer">
            <img
              className="logo-img"
              src="/favicon.png"
              href="/src/View/Pages/Home.jsx"
              id="navbar__logo"
              alt="logo-upperz"
            />
            <span id="navbar__left__title">Upperz</span>
          </div>
          <div className="content_footer">
            <a
              href="https://community.upper-z.com/"
              className="text-link-footer"
            >
              Communauté
            </a>
            <a href="/" className="text-link-footer">
              Expertise
            </a>
            <a href="https://t.me/UpperzDevs" className="text-link-footer">
              Telegram
            </a>
            <a href="/portofolio" className="text-link-footer">
              Portofolio
            </a>
            <a href="/blog" className="text-link-footer">
              Blog
            </a>
            <a
              href="https://community.upper-z.com/formation"
              className="text-link-footer"
            >
              Formation
            </a>
          </div>
        </div>
        <div className="bas_content">
          <div className="text-copyright">
            {/* <strong style={{ fontSize: "16px" }}>©</strong> 2023 by
            <strong style={{ color: "#647be3" }}> Upperz</strong> */}
            Copyright © 2023 Upperz. All rights reserved.
          </div>
          <div className="div-rs-footer">
            <a href="https://web.facebook.com/upperzdev">
              <img
                src="/facebook-svgrepo-com.png"
                loading="lazy"
                width="26"
                height="26"
                alt="logo instagram en blanc"
                className="img-logo-rs"
              />
            </a>

            <a href="https://www.linkedin.com/company/upperz/">
              <img
                src="/linkedin-svgrepo-com (1).png"
                loading="lazy"
                width="26"
                height="26"
                alt="icon de linkedin en blanc"
                className="img-logo-rs"
              />
            </a>
            <a href="https://twitter.com/Upperz_dev">
              <img
                src="/twitter-svgrepo-com (6).png"
                loading="lazy"
                width="20"
                height="20"
                alt="logo twitter blanc"
                className="img-logo-rs"
              />
            </a>
            <a href="https://www.instagram.com/upperz_dev/">
              <img
                src="/instagram-svgrepo-com.png"
                loading="lazy"
                width="26"
                height="26"
                alt="logo instagram en blanc"
                className="img-logo-rs"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
