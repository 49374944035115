import { Box, createTheme } from "@mui/material";
import React from "react";

const HeadSection = ({
  label,
  title,
  description,
  textAlign,
  padding,
  margin,
  padding_mobile,
  textAlign_mobile,
}) => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });
  return (
    <Box
      className="desc_span_expertise"
      sx={{
        textAlign: textAlign,

        [theme.breakpoints.down("sm")]: {
          padding: padding_mobile,

          textAlign: textAlign_mobile,
        },
      }}
    >
      <div className="title_expertise">{label}</div>
      <div className="desc1">
        {title?.split(",")[0]}
        <span className="desc12">{title?.split(",")[1]}</span>
      </div>
      <p class="subtitle">{description}</p>
    </Box>
  );
};

export default HeadSection;
