import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const Testmony = () => {
  const { home } = useSelector((state) => state.data);
  let section_client = home[9]?.data?.data;

  return (
    <div className="testmony">
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyContent="center"
      >
        {section_client?.map((item) => {
          //item.image_url
          return (
            <Grid item xs={4}>
              <div className="testmony_item">
                <div className="img_test">
                  {item?.image_url === null ? (
                    <div
                      style={{ backgroundColor: "#dddddd" }}
                      className="img"
                    ></div>
                  ) : (
                    <img src={item?.image_url} alt="..." className="img" />
                  )}
                </div>

                <div className="desc">
                  <div className="desc_title">{item.full_name}</div>
                  <div className="subtitle">{item.function}</div>
                  <div className="desc_text">
                    <p>{item.comment}</p>
                  </div>
                </div>
                <div
                  className="social"
                  style={{ display: "flex", alignItems: "center", gap: "15px" }}
                >
                  <a href={item?.github_link}>
                    <img
                      src="/github-fill-svgrepo-com (1).png"
                      loading="lazy"
                      width="26"
                      height="26"
                      alt="logo instagram en blanc"
                      className="img-logo-rs"
                    />
                  </a>
                  <a href={item?.linkedin_link}>
                    <img
                      src="/linkedin-svgrepo-com (3).png"
                      loading="lazy"
                      width="26"
                      height="26"
                      alt="icon de linkedin en blanc"
                      className="img-logo-rs"
                    />
                  </a>
                  <a href={item?.twitter_link}>
                    <img
                      src="/twitter-svgrepo-com (4).png"
                      loading="lazy"
                      width="26"
                      height="26"
                      alt="logo twitter blanc"
                      className="img-logo-rs"
                    />
                  </a>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Testmony;
