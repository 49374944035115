const LOADING = "LOADING";
const HOME_DATA = "HOME_DATA";
const TECHNO_DATA = "TECHNO_DATA";
const PORTOFOLIO_DATA = "PORTOFOLIO_DATA";
const BLOG_DATA = "BLOG_DATA";
const DETAIL_PROJECT = "DETAIL_PROJECT";
const SEND_FORM = "SEND_FORM";

export {
  LOADING,
  HOME_DATA,
  TECHNO_DATA,
  PORTOFOLIO_DATA,
  BLOG_DATA,
  DETAIL_PROJECT,
  SEND_FORM,
};
