import React from "react";
import ScrollToTop from "./ScrollToTop";

const Loader = () => {
  return (
    <div className="content">
      <div class="loader">
        <span>{`{`}</span>
        <span>{`}`}</span>
      </div>
    </div>
  );
};

export default Loader;
