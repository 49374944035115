import {
  // LOADING,
  HOME_DATA,
  BLOG_DATA,
  TECHNO_DATA,
  PORTOFOLIO_DATA,
  DETAIL_PROJECT,
  SEND_FORM,
} from "./data_type";

// const stateAction = ({ isLoading, isSuccess, isError, data }) => ({
//   type: LOADING,
//   payload: { isLoading, isSuccess, isError, data },
// });
// const stateData = ({ data }) => ({
//   type: DATA,
//   payload: { data },
// });
const stateHome = ({ isLoading, isSuccess, isError, home }) => ({
  type: HOME_DATA,
  payload: { isLoading, isSuccess, isError, home },
});
const stateBlog = ({ isLoading, isSuccess, isError, blog }) => ({
  type: BLOG_DATA,
  payload: { isLoading, isSuccess, isError, blog },
});
const stateTechno = ({ isLoading, isSuccess, isError, tech }) => ({
  type: TECHNO_DATA,
  payload: { isLoading, isSuccess, isError, tech },
});
const statePorto = ({ isLoading, isSuccess, isError, porto }) => ({
  type: PORTOFOLIO_DATA,
  payload: { isLoading, isSuccess, isError, porto },
});
const stateDetail = ({ isLoading, isSuccess, isError, detail }) => ({
  type: DETAIL_PROJECT,
  payload: { isLoading, isSuccess, isError, detail },
});
const stateSend = ({ isLoading, isSuccess, isError, sendForm }) => ({
  type: SEND_FORM,
  payload: { isLoading, isSuccess, isError, sendForm },
});

export {
  // stateAction,
  // stateData,
  stateSend,
  stateHome,
  stateBlog,
  stateTechno,
  statePorto,
  stateDetail,
};
