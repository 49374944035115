import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Basic_URL from "../config/Basic_url.js";
import {  statePorto } from "../../redux/actions/data.action.js";

const Getdata_portofolio = () => {
  let dispatch = useDispatch();
  const [data, setData] = useState([]);

  const _axios = axios.create({
    baseURL: Basic_URL,
  });

  const getData = async () => {
    try {
      dispatch(
        statePorto({ isLoading: true, isSuccess: "", isError: "", porto: "" })
      );
      const response1 = await _axios.get(`/home/section-one`, {
        params: {
          tag_section: "Portofolio",
        },
      });
      setData(response1);
      const response2 = await _axios.get(`/home/section-one/cards`);
      setData(response2);
      // const response3 = await _axios.get(`/home/section-two`);
      // setData(response3);
      // const response4 = await _axios.get(`/home/section-two/cards`);
      // setData(response4);
      // const response5 = await _axios.get(`/home/expertises`);
      // setData(response5);
      // const response6 = await _axios.get(`/home/faq`);
      // setData(response6);
      // const response7 = await _axios.get(`/home/faq/cards`);
      const response8 = await _axios.get(`/home/customers`);
      setData(response8);
      const response9 = await _axios.get(`/home/customer/images`);
      setData(response9);
      // const response10 = await _axios.get(`/home/testimonies`);
      // setData(response10);
      dispatch(
        statePorto({
          isLoading: false,
          isSuccess: [
            response1?.status,
            response2?.status,
            // response3?.status,
            // response4?.status,
            // response5?.status,
            // response6?.status,
            // response7?.status,
            response8?.status,
            response9?.status,
            // response10?.status,
          ],
          porto: [
            response1?.data,
            response2?.data,
            // response3?.data,
            // response4?.data,
            // response5?.data,
            // response6?.data,
            // response7?.data,
            response8?.data,
            response9?.data,
            // response10?.data,
          ],
          isError: "",
        })
      );
    } catch (error) {
      dispatch(
        statePorto({
          isLoading: false,
          isSuccess: "",
          isError: error?.message,
          porto: "",
        })
      );
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return getData;
};
export default Getdata_portofolio;
