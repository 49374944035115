import React from "react";
import { Card, Grid } from "@mui/material";
import { useSelector } from "react-redux";
const Expertise = () => {
  const { home } = useSelector((state) => state.data);
  let tabContent = [
    {
      background: "",
      img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/625574faa24f22711fe7aa43_icon_strategie.png",
      title: "Stratégie",
      desc: "Pour commencer, il est important pour nous de bien comprendre vos enjeux, vos problématiques et vos objectifs. Ainsi nous pourrons vous apporter toute notre expertise sur les choix stratégiques et techniques à mettre en place pour la réussite de votre projet.",
    },
    {
      background: "",
      img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/625574fc7557f2d555a52bc7_icon_ux_research.png",
      title: "UX Research",
      desc: "Maintenant que ",
    },
    {
      background: "",
      img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/625574fa5f84c763e90856d3_icon_ui_design.png",
      title: "UI Design",
      desc: "Dès la création du parcours utilisateur et des interfaces agréables, pratiques et faciles à prendre en main. Nos designer passent à la partie UI design, afin d'intégrer une charte graphique simple, épurée pour une expérience interactive optimale.",
    },
    {
      background: "",
      img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/625574f9b3a3bba4c1d34f45_icon_developpement.png",
      title: "Développement",
      desc: "Une fois les interfaces finalisées, nous lançons enfin notre équipe de développeur passionnée, développer votre application. Grâce à notre méthodologie Agile, vous recevez des versions testables toutes les semaines, ce qui vous laisse tout le temps nécessaire pour les tester et faire des ajustements.",
    },
    {
      background: "",
      img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/625574fa3d84b677aa42ea4b_icon_tests.png",
      title: "Tests",
      desc: "L'une des étapes clés à ne pas négliger pour avoir une application de qualité, ce sont bien évidement les tests. C'est pourquoi nous mettons en place un process qualité, avec des tests unitaires, des tests d intégrations ainsi que des tests humains pour valider la conformité de chaque fonctionnalité.",
    },
    {
      background: "",
      img: "https://uploads-ssl.webflow.com/62286107f2783186ccd3f75b/625574f964d8fe480aebd068_icon_lancement.png",
      title: "Lancement",
      desc: "Vient l’heure du lancement en production, une étape que vous attendez avec impatience. Bien entendu, notre collaboration ne se termine pas là ! Nous continuerons à vous accompagner durant une période de lancement pour apporter des correctifs techniques et toujours garantir une application de qualité.",
    },
  ];

  let data_section_expertise = home;

  return (
    <div className="expertise">
      {data_section_expertise?.map((item, index) => {
        if (index === 2) {
          return (
            <>
              {item?.data?.data?.map((i) => {
                return (
                  <div className="onboarding_expertise">
                    <div className="title_expertise">{i?.label}</div>
                    <div className="desc_expertise">
                      <div className="desc1">
                        <div className="desc11">
                          {i?.title?.split(",")[0]}
                          <span className="desc12">
                            {i?.title?.split(",")[1]}
                          </span>
                        </div>
                      </div>
                      <div className="desc2">
                        <p>{i?.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          );
        } else if (index === 3) {
          return (
            <div className="expertise_grid">
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {item?.data?.data?.map((i) => {
                  return (
                    <Grid item xs={4}>
                      <div className="card_grid">
                        <div className="img_expertise">
                          <img src={i?.image_url} alt="..." id="img" />
              
                        </div>
                        <div className="content_expertise">
                          <h1>{i?.title}</h1>
                          <p>{i?.description}</p>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          );
        }
      })}
    </div>
  );
};

export default Expertise;
