import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const Portofolio_client = () => {
  const { porto } = useSelector((state) => state.data);
  let section_client = porto[2]?.data?.data;
  let section_client_img = porto[3]?.data?.data;
  console.log(section_client);
  return (
    <div className="client">
      {section_client?.map((item) => {
        return (
          <div class="header_section">
            <div className="title_client">{item?.label}</div>
            <div className="desc1">
              {item?.title?.split(",")[0]}
              <span className="desc12">{item?.title?.split(",")[1]}</span>
            </div>
          </div>
        );
      })}

      <div className="client_grid">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 5, sm: 10, md: 16 }}
          alignContent="center"
          justifyContent="center"
        >
          {section_client_img?.map((item) => {
            //image_url
            return (
              <Grid item xs={2}>
                <div>
                  <img src={item?.image_url} alt="..." className="img" />
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default Portofolio_client;
