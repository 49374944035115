import { Button } from "@mui/material";
import React from "react";

const Btn_without_bg = ({ value, action }) => {
  return (
    <div className="btn_project">
      <Button id="btn_project" href={action}>
        {value}
      </Button>
    </div>
  );
};

export default Btn_without_bg;
