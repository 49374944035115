import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const Layout_app = ({
  background,
  title,
  desc,
  image,
  link,
  genre,
  idProject,
}) => {
  const { data } = useSelector((state) => state.data);
  // let data_section_two = data[1]?.data?.data;
  return (
    <NavLink
      className="layout_item"
      style={{ background: background }}
      to={`/portofolio/detail/${idProject}`}
      id="app"
    >
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={6}>
          <div className="layout_desc">
            <div className="item_layout">
              <h1 className="title">{title}</h1>
              <p className="desc">{desc}</p>
              {genre}
              {/* <div className="tecnos">
                <p id="element">ui/ux design</p>
                <p id="element">web</p>
                <p id="element">android</p>
                <p id="element">ios</p>
              </div> */}
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="layout_img">
            <img src={image} alt="..." id="img" />
          </div>
        </Grid>
      </Grid>
    </NavLink>
  );
};

export default Layout_app;
