import React from "react";
import Expertise from "../components/Expertise";
import Span_expertise from "../components/Span_expertise";
import Faq from "../components/Faq";
import Client from "../components/Client";
import Testmony from "../components/Testmony";
import Layout_onboarding_side from "../layout/Layout_onboarding_side";
import Layout_app from "../layout/Layout_app";
import Btn_without_bg from "../layout/model/Btn_without_bg";
import { useSelector } from "react-redux";
import Loader from "../layout/Loader";
import Bad_request_screen from "../layout/model/Bad_request_screen";
import Getdata_home from "../../controller/app/data/Getdata_home";
//dev branch

const Home = () => {
  Getdata_home();
  const { home, isLoading, isError } = useSelector((state) => state.data);

  let data_section_one = home;

  if (isLoading) {
    return <Loader />;
  } else if (isError !== "" || home === "") {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "78.7vh",
          }}
        >
          <div style={{ width: "320px" }}>
            <img src="/500.jpg" alt="..." style={{ width: "100%" }} />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="Home">
          {data_section_one?.map((item, index) => {
            if (index === 0) {
              return (
                <>
                  {item?.data?.data?.map((i) => {
                    return (
                      <Layout_onboarding_side
                        title={i?.title}
                        description={i?.description}
                        btn={i?.bt_designation}
                        tag="home"
                        action="/contact"
                        img1="/landing_img.webp"
                        img_christ="/pngegg.png"
                      />
                    );
                  })}
                </>
              );
            } else if (index === 1) {
              const ThreeElement = item[1]?.data?.data?.slice(0, 3);
              // console.log(item?.data?.data?.length);
              if (item?.data?.data.length > 3) {
                return (
                  <div className="home">
                    {item?.data?.data?.slice(0, 3).map((i) => {
                      return (
                        <Layout_app
                          background={i?.background}
                          title={i?.title}
                          desc={i?.description}
                          image={i?.image_url}
                          link={i?.link}
                          idProject={i?.id}
                          genre={
                            <div className="tecnos">
                              {i?.tags?.map((l) => {
                                return (
                                  <p
                                    id="element"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4px",
                                    }}
                                  >
                                    {l?.genre?.image_url === null ? (
                                      <div></div>
                                    ) : (
                                      <img
                                        src={l?.genre?.image_url}
                                        width="16px"
                                        height="16px"
                                        style={{ borderRadius: "4px" }}
                                      />
                                    )}

                                    {l?.genre?.designation}
                                  </p>
                                );
                              })}
                            </div>
                          }
                        />
                      );
                    })}
                    <Btn_without_bg
                      value="Voir tous nos projets"
                      action="/portofolio"
                    />
                    <Expertise />
                  </div>
                );
              } else {
                return (
                  <div className="home">
                    {item?.data?.data?.map((i) => {
                      return (
                        <Layout_app
                          background={i?.background}
                          title={i?.title}
                          desc={i?.description}
                          image={i?.image_url}
                          link={i?.link}
                          idProject={i?.id}
                          genre={
                            <div className="tecnos">
                              {i?.tags?.map((l) => {
                                return (
                                  <p
                                    id="element"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4px",
                                    }}
                                  >
                                    {l?.genre?.image_url === null ? (
                                      <div></div>
                                    ) : (
                                      <img
                                        src={l?.genre?.image_url}
                                        width="16px"
                                        height="16px"
                                        style={{ borderRadius: "4px" }}
                                      />
                                    )}

                                    {l?.genre?.designation}
                                  </p>
                                );
                              })}
                            </div>
                          }
                        />
                      );
                    })}
                    <Btn_without_bg
                      value="Voir tous nos projets"
                      action="/portofolio"
                    />
                    <Expertise />
                  </div>
                );
              }
            } else if (index === 2 && index === 3) {
              return (
                <div className="home">
                  <div className="model_app_item">
                    <Expertise />
                  </div>
                </div>
              );
            } else if (index === 4) {
              return (
                <>
                  <Span_expertise />
                </>
              );
            } else if (index === 5) {
              console.log(item);
              return (
                <>
                  <div className="home">
                    <Faq />
                  </div>
                </>
              );
            } else if (index === 7) {
              console.log(item, "7");
              return (
                <>
                  <div className="home">
                    <Client />
                  </div>
                </>
              );
            } else if (index === 9) {
              return (
                <>
                  <div className="home">
                    <Testmony />
                  </div>
                </>
              );
            }
          })}

          {/* <div className="home">
              <div className="model_app_item">
                {data_section_two?.map((item) => {
                  return (
                    <Layout_app
                      background={item?.background}
                      title={item?.title}
                      desc={item?.description}
                      image={item?.image_url}
                      link={item?.link}
                      idProject={item?.id}
                      genre={
                        <div className="tecnos">
                          {item?.genres?.split(",").map((i) => {
                            return <p id="element">{i}</p>;
                          })}
                        </div>
                      }
                    />
                  );
                })}
                <Btn_without_bg
                  value="Voir tous nos projets"
                  action="/portofolio"
                />
                <Expertise />
              </div>
            </div> */}
          {/* <Span_expertise />
            <div className="home">
              <Faq />
              <Client />
              <Testmony />
            </div> */}
        </div>
      </>
    );
  }
};

export default Home;
