import {
  HOME_DATA,
  BLOG_DATA,
  TECHNO_DATA,
  PORTOFOLIO_DATA,
  DETAIL_PROJECT,
  SEND_FORM,
} from "../actions/data_type";

const initialeState = {
  isLoading: false,
  isSuccess: "",
  isError: "",
  data: "",
  home: "",
  blog: "",
  tech: "",
  porto: "",
  detail: "",
  sendForm: "",
};

export const dataReducer = (state = initialeState, actions) => {
  switch (actions.type) {
    case HOME_DATA:
      return {
        ...state,
        ...actions.payload,
      };
    case BLOG_DATA:
      return {
        ...state,
        ...actions.payload,
      };
    case TECHNO_DATA:
      return {
        ...state,
        ...actions.payload,
      };
    case PORTOFOLIO_DATA:
      return {
        ...state,
        ...actions.payload,
      };
    case DETAIL_PROJECT:
      return {
        ...state,
        ...actions.payload,
      };
    case SEND_FORM:
      return {
        ...state,
        ...actions.payload,
      };
    default:
      return state;
  }
};
