import { Button, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { BiSolidDownArrow } from "react-icons/bi";

const Navbar = ({ menu }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = React.useState(menu[1].designation[0].tecn);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    menu[1].designation[0].tecn
  );

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    setIsOpen(false);
    localStorage.setItem("selectedOption", option);
  };
  let navigator = useNavigate();
  const handleClickContact = () => {
    setIsOpen(false);
    navigator("/contact");
    return;
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  // useEffect(() => {
  //   const storedOption = localStorage.getItem("selectedOption");
  //   if (storedOption) {
  //     setSelectedOption(storedOption);
  //   }
  // }, []);
  // useEffect(() => {
  //   return () => {
  //     localStorage.removeItem("selectedOption");
  //   };
  // }, []);
  // add christmas

  return (
    <div className="navbar_global">
      <div className="navbar">
        <div className="navbar__left">
          <a href="/">
 
            <img
              class="logo-img"
              src="/favicon.png"
              href="/"
              id="navbar__logo"
              alt="logo-upperz"
            />
          </a>
        </div>
        <div className="navbar__right">
          <div className={`nav__menu ${isOpen && "open"}`}>
            {menu.map((menus, index) => {
              return (
                <>
                  {index === 1 ? (
                    <>
                      <div
                        className={`${menus?.className} dropdown`}
                        onMouseEnter={handleDropdownToggle}
                        onMouseLeave={handleDropdownToggle}
                      >
                        <button
                          className="dropbtn"
                          style={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          {selectedOption} <BiSolidDownArrow fontSize={10} />
                        </button>
                        {isDropdownOpen && (
                          <div className="dropdownContent">
                            {menus?.designation?.map((i) => {
                              return (
                                <NavLink
                                  to={`${i?.linkd}`}
                                  onClick={() => handleOptionClick(i?.tecn)}
                                  className={`${menus.className} `}
                                >
                                  {i?.tecn}
                                </NavLink>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <NavLink
                      to={`${menus.link}`}
                      onClick={() => setIsOpen(false)}
                      className={`${menus.className} `}
                    >
                      {menus?.designation}
                    </NavLink>
                  )}
                </>
                // <NavLink
                //   to={`${menus.link}`}
                //   onClick={() => setIsOpen(false)}
                //   className={`${menus.className} `}
                // >
                //   {menus.designation}
                // </NavLink>
              );
            })}

            <div className="btn_desk">
              <NavLink
                variant="contained"
                onClick={() => {
                  setIsOpen(false);
                }}
                className="btn_nav"
                to="/contact"
                style={{ textDecoration: "none", borderRadius: "8px" }}
              >
                Contact
              </NavLink>
            </div>
          </div>
          <div className="btn">
            <NavLink
              variant="contained"
              onClick={() => {
                setIsOpen(false);
              }}
              className="btn_nav"
              to="/contact"
              style={{ textDecoration: "none", borderRadius: "8px" }}
            >
              Contact
            </NavLink>
          </div>
        </div>
        <div
          className={`nav__toggle ${isOpen && "open"}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="line1"></div>
          {/* <p
            className="upperz"
            style={{
              display: "flex",
              alignItems: "center",
              color: "black",
              textDecoration: "none",
              fontWeight: "900",
              fontSize: "14px",
            }}
          >
            Upperz
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
