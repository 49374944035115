// const data_menus = Getdata_menu();

let menus = [
  {
    Id: "352410",
    designation: "Acceuil",
    toggle: false,
    link: "/",
    className: "nav__link ",
  },
  {
    Id: "459711",
    // designation: "Technologie",
    designation: [
      { tecn: "Flutter", linkd: "/flutter" },
      { tecn: "React", linkd: "/reactjs" },
    ],
    toggle: false,
    link: "/technology",
    className: "nav__link ",
  },
  {
    Id: "364826",
    designation: "Portofolio",
    toggle: false,
    link: "portofolio",
    className: "nav__link ",
  },
  {
    Id: "356736",
    designation: "Blog",
    toggle: false,
    link: "blog",
    className: "nav__link ",
  },
];

// let menus = data_menus.map((menu) => {
//   return [
//     {
//       Id: menu.Id,
//       //   link: `/${menu.designation}`,
//       name: menu.designation,
//       className: "nav__link ",
//     },
//   ];
// });
export default menus;
